import React from 'react'

import bulb from '../assets/icons/bulb'

const AnimatedLogo2 = () => {
  return (
    <div css={sContainer}>
      <div css={sLogoL}></div>
      <div css={sLogoM}></div>
      <div css={sLogoS}></div>
      <div css={sBulb}>{bulb}</div>
    </div>
  )
}

const sContainer = {
  position: 'relative',
  width: 270,
  height: 270,
  animation: 'appear 1.2s 0.6s 1 alternate both',

  '@keyframes appear': {
    '0%': { opacity: 0, transform: 'scale(0.6)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  },
}

const sLogoL = {
  position: 'absolute',
  width: 250,
  height: 250,
  top: '50%',
  left: '50%',
  borderRadius: '20%',
  background:
    'linear-gradient(135deg, #F8E588 0%, #F0DA78 20%, #D3B03A 40%, #805100 90%)',
  animation:
    'logoLbrightness 1.2s 0.9s infinite both alternate ease-out, logoLscaling 1.2s 0.9s infinite both alternate ease-in-out',

  '@keyframes logoLbrightness': {
    '0%': { opacity: 0.1, filter: 'brightness(90%) contrast(100%)' },
    '60%': { filter: 'brightness(90%) contrast(100%)' },
    '100%': { opacity: 1, filter: 'brightness(110%) contrast(100%)' },
  },

  '@keyframes logoLscaling': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(0.95)',
      borderRadius: '50%',
    },
    '100%': {
      transform: 'translate(-50%, -50%) scale(1.05)',
      borderRadius: '25%',
    },
  },
}

const sLogoM = {
  position: 'absolute',
  width: 250,
  height: 250,
  top: '50%',
  left: '50%',
  borderRadius: '44%',
  background: 'linear-gradient(135deg, #FFEA80 12%, #B38000 80%)',
  animation:
    'logoMbrightness 1.2s 0.6s infinite both alternate ease-out, logoMscaling 1.2s 0.6s infinite both alternate ease-in-out',

  '@keyframes logoMbrightness': {
    '0%': { opacity: 0.6, filter: 'brightness(90%) contrast(100%)' },
    '80%': { opacity: 0.6, filter: 'brightness(90%) contrast(100%)' },
    '100%': { opacity: 1, filter: 'brightness(125%) contrast(110%)' },
  },

  '@keyframes logoMscaling': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(0.75)',
      borderRadius: '50%',
    },
    '100%': {
      transform: 'translate(-50%, -50%) scale(1.02)',
      borderRadius: '44%',
    },
  },
}

const sLogoS = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 195,
  height: 195,
  borderRadius: '100px',
  background: 'linear-gradient(135deg, #FFE873 12%, #CC9B00 80%)',
  animation:
    'logoSbrightness 1.2s 0.3s infinite both alternate ease-in, logoSscaling 1.2s 0.3s infinite both alternate ease-in-out',

  '@keyframes logoSscaling': {
    '0%': { transform: 'translate(-50%, -50%) scale(0.7)' },
    '100%': { transform: 'translate(-50%, -50%) scale(1.1)' },
  },

  '@keyframes logoSbrightness': {
    '0%': { opacity: 0.75, filter: 'brightness(90%) contrast(100%)' },
    '80%': { opacity: 0.75, filter: 'brightness(100%) contrast(100%)' },
    '100%': { opacity: 1, filter: 'brightness(125%) contrast(110%)' },
  },
}

const sBulb = {
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  animation:
    'bulbbrightness 1.2s 0s infinite both alternate ease-out, bulbscaling 1.2s 0s infinite both alternate ease-in-out, enter 1.2s 0s 1 both alternate ease-in-out',

  '@keyframes bulbscaling': {
    '0%': { transform: 'translate(-50%, -50%) scale(0.98)' },
    '100%': { transform: 'translate(-50%, -50%) scale(1.02)' },
  },

  '@keyframes bulbbrightness': {
    '0%': { filter: 'brightness(75%) contrast(75%)' },
    '60%': { filter: 'brightness(100%) contrast(75%)' },
    '100%': { filter: 'brightness(100%) contrast(125%)' },
  },

  '@keyframes enter': {
    '0%': { opacity: 0, transform: 'translate(-50%, 25%)' },
    '50%': { opacity: 0.75, transform: 'translate(-50%, -60%)' },
    '100%': { opacity: 1, transform: 'translate(-50%, -50%)' },
  },
}

export default AnimatedLogo2
