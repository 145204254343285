import React from 'react'

import AnimatedLogo2 from '../components/animatedLogo2'

const AnimationPage = () => {
  return (
    <div
      css={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%) scale(3)',
      }}
    >
      <AnimatedLogo2 />
    </div>
  )
}

export default AnimationPage
