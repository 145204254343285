import React from 'react'
export default (
  <svg
    id="bulb"
    xmlns="http://www.w3.org/2000/svg"
    width="103.769"
    height="138.978"
    viewBox="0 0 103.769 138.978"
  >
    <path
      id="Line2"
      data-name="Line 2"
      d="M9.157,0h12.21a9.157,9.157,0,0,1,0,18.314H9.157A9.157,9.157,0,0,1,9.157,0Z"
      transform="translate(36.711 120.664)"
      fill="#332600"
    />
    <path
      id="Line1"
      data-name="Line 1"
      d="M9.157,0H42.733a9.157,9.157,0,0,1,0,18.314H9.157A9.157,9.157,0,0,1,9.157,0Z"
      transform="translate(26.028 94.715)"
      fill="#332600"
    />
    <path
      id="Circle"
      data-name="Bulb"
      d="M51.89,103.769a51.9,51.9,0,0,1-20.2-99.692,51.883,51.883,0,0,1,56.877,84.5,51.705,51.705,0,0,1-36.68,15.194Zm0-85.452A33.572,33.572,0,1,0,85.462,51.889,33.612,33.612,0,0,0,51.89,18.317Z"
      transform="translate(0 0)"
      fill="#332600"
    />
  </svg>
)
